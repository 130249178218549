import React from "react";

const Hero = () => {
  return (
    <div className="bg-gray-800 text-white py-20">
      <div className="container mx-auto px-6">
        <div className="text-center mb-12">
          <h1 className="text-5xl font-bold mb-4">SSP - Stake Slot Pilot</h1>
          <p className="text-xl mb-8">
            Your ultimate companion for spin on stake, slot machines, and
            originals
          </p>
          <a
            href="https://discord.gg/fKQJw8Bcaz"
            target="_blank"
            rel="noopener noreferrer"
            className="inline-block bg-gradient-to-r from-blue-500 to-blue-700 text-white px-6 py-3 rounded-md text-lg font-semibold hover:from-blue-600 hover:to-blue-800 transition-all duration-300 shadow-lg"
          >
            Join Now
          </a>
        </div>
        <div className="max-w-4xl mx-auto">
          <div className="aspect-w-16 aspect-h-9 rounded-lg overflow-hidden shadow-2xl">
            <video
              className="w-full h-full object-cover"
              controls
              poster="/tmb/tmb.png"
            >
              <source src="/videos/ssp_demo.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
