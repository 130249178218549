import React, { useState } from "react";
import { FiMenu, FiX } from "react-icons/fi";

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <header className="bg-gray-800 shadow-md">
      <nav className="container mx-auto px-6 py-3">
        <div className="flex justify-between items-center">
          <div className="text-2xl font-bold text-white">SSP</div>
          <div className="hidden md:flex">
            <a
              href="#features"
              className="text-gray-300 hover:text-white px-3 py-2 transition-colors"
            >
              Features
            </a>
            <a
              href="https://discord.gg/fKQJw8Bcaz"
              target="_blank"
              rel="noopener noreferrer"
              className="bg-blue-600 hover:bg-blue-700 text-white px-4 py-2 rounded-md ml-4 transition-colors"
            >
              Get Started
            </a>
          </div>
          <button className="md:hidden text-white" onClick={toggleMenu}>
            {isMenuOpen ? <FiX size={24} /> : <FiMenu size={24} />}
          </button>
        </div>

        {/* Mobile menu */}
        {isMenuOpen && (
          <div className="md:hidden mt-4">
            <a
              href="#features"
              className="block text-gray-300 hover:text-white py-2 transition-colors"
              onClick={toggleMenu}
            >
              Features
            </a>
            <a
              href="https://discord.gg/fKQJw8Bcaz"
              target="_blank"
              rel="noopener noreferrer"
              className="block bg-blue-600 hover:bg-blue-700 text-white px-4 py-2 rounded-md mt-2 transition-colors"
              onClick={toggleMenu}
            >
              Get Started
            </a>
          </div>
        )}
      </nav>
    </header>
  );
};

export default Header;
