import React from "react";
import { FiArrowRight } from "react-icons/fi";

const CTA = () => {
  return (
    <section className="py-20 bg-gray-800">
      <div className="container mx-auto px-6 text-center">
        <h2 className="text-4xl font-bold text-white mb-4">
          Ready to Elevate Your Gaming Experience?
        </h2>
        <p className="text-xl text-gray-300 mb-8">
          Join our Discord community and start using SSP today!
        </p>
        <a
          href="https://discord.gg/fKQJw8Bcaz"
          target="_blank"
          rel="noopener noreferrer"
          className="inline-flex items-center bg-gradient-to-r from-blue-500 to-blue-700 text-white px-8 py-3 rounded-md text-lg font-semibold hover:from-blue-600 hover:to-blue-800 transition-all duration-300 shadow-lg"
        >
          Join Our Discord
          <FiArrowRight className="ml-2" />
        </a>
      </div>
    </section>
  );
};

export default CTA;
