import React from "react";
import { FiActivity, FiBarChart2, FiGift } from "react-icons/fi";

const Features = () => {
  const features = [
    {
      icon: <FiActivity size={24} />,
      title: "Automated Spins",
      description: "Let SSP handle your spins for maximum efficiency",
    },
    {
      icon: <FiBarChart2 size={24} />,
      title: "Smart Betting",
      description: "Utilize advanced algorithms for optimal betting strategies",
    },
    {
      icon: <FiGift size={24} />,
      title: "Real-time Analytics",
      description: "Get instant insights on your gaming performance",
    },
  ];

  return (
    <section id="features" className="py-20 bg-gray-900">
      <div className="container mx-auto px-6">
        <h2 className="text-3xl font-semibold text-center text-white mb-12">
          Features
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {features.map((feature, index) => (
            <div
              key={index}
              className="bg-gray-800 rounded-lg shadow-md p-6 hover:shadow-lg transition-shadow duration-300"
            >
              <div className="text-blue-400 mb-4">{feature.icon}</div>
              <h3 className="text-xl font-semibold mb-4 text-white">
                {feature.title}
              </h3>
              <p className="text-gray-300">{feature.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Features;
